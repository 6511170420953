/* eslint-disable react-hooks/exhaustive-deps */
import { stopPropagation } from '../../../../../../utils';
import { Modal, Button } from 'semantic-ui-react';

export const DeactivateExternalLinked = (props) => {
  const {
    confirmDeactivateModel: modal,
    toggleConfirmDeactivateModel: toggle,
    setConfirmedDeactivation: deactivate,
  } = props;

  const confirmDeactivate = () => {
    deactivate(true);
    toggle();
  };

  return (
    <Modal
      open={modal}
      onOpen={() => toggle()}
      onClick={(e) => stopPropagation(e)}
      onFocus={(e) => stopPropagation(e)}
      closeIcon={
        <div className="new-activity-modal-close-icon" onClick={() => toggle()}>
          &times;
        </div>
      }
      size="tiny"
    >
      <Modal.Header>
        <div className="small-modal-header">
          Deactivate External Linked Activity
        </div>
      </Modal.Header>
      <Modal.Content className="deactivation-modal-content">
        <div className="no-feature-warning-box w-100">
          <div className="padding-issue">
            The owner of this linked activity is a user in an external Takso
            account. On confirmation of deactivation, the activity record will
            be transferred to the existing activity manager’s account where they
            can choose to continue or delete the activity.
          </div>
        </div>
        <p>Proceed with deactivation.</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => toggle()}
          className="activity-activation-cancel-btn"
        >
          No
        </Button>
        <Button
          onClick={() => confirmDeactivate()}
          className="new-collective-save-btn"
          content="Yes"
        />
      </Modal.Actions>
    </Modal>
  );
};
