import './organization.scss';

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Divider,
  Form,
  Grid,
  Icon,
  Modal,
  Segment,
} from 'semantic-ui-react';

import {
  CustomTable,
  EmptyContainer,
  LocationSearchInput,
} from '../../../components';
import {
  CustomCheckbox,
  CustomDropdown,
  CustomInput,
} from '../../../components/form';
import {
  createOrgVenues,
  deleteOrgVenues,
  getOrgVenuesList,
  getOrgVenuesTypes,
  getVenueTypes,
  updateOrgVenues,
} from '../../../store/actions';
import {
  assign,
  compact,
  find,
  get,
  isEmpty,
  map,
  sortBy,
  uniq,
} from '../../../utils/lodash';
import {
  addVenueInitialValues,
  addVenueValidation,
} from '../../../validations/addVenueTypeValidation';
import { CheckboxComp } from '../../../components/form/checkboxes/checkBoxGroup';
import { countries } from 'country-list-json';
import { MAP_DEFAULT_COUNTRY_NAME } from '../../../utils/constant';

const VenueForm = (props) => {
  const [venueTypesDescription, setVenueTypesDescription] = useState(null);
  const [venueTypesIsOnline, setVenueTypesIsOnline] = useState(false);
  const [isOwned, setIsOwned] = useState(false);
  const [initialValues, setInitialValues] = useState(
    addVenueInitialValues({
      ...props.venue,
      country: get(props, 'venue.country', MAP_DEFAULT_COUNTRY_NAME),
    })
  );

  const [selectedOption, setSelectedOptions] = useState([]);

  const dispatch = useDispatch();

  const { orgVenueTypes } = useSelector((state) => state.extra);
  const [subOptions, setSubOptions] = useState([]);
  const loading = useSelector((state) => state.organization.loading);
  const auth = useSelector((state) => state.authentication);

  useEffect(() => {
    const { venue } = props;
    setVenueTypesDescription(get(venue, 'venueType.description'));
    setVenueTypesIsOnline(get(venue, 'venueType.isOnline'));

    setSubOptions(get(venue, 'venueType.option', []));
    if (venue && venue.owned === 1) {
      setIsOwned(true);
    } else {
      setIsOwned(false);
    }
  }, []);

  const handleValidSubmit = async (values, resetForm) => {
    try {
      const { isNew, toggle, projectId, resetPage, activity = {} } = props;
      const form = {};
      delete values.isOwned;

      assign(form, values);
      isOwned ? (form.owned = true) : (form.owned = false);

      const currentActivityId = get(activity, 'currentActivity.id', null);
      const orgId = auth.user.organisation ? auth.user.organisation.id : null;

      form.activityId = currentActivityId;

      values.organisationId && delete values.organisationId;
      values.createdBy && delete values.createdBy;

      form.subVenueOptions = selectedOption;
      if (venueTypesIsOnline) {
        delete form.country;
        delete form.streetAddress;
        delete form.streetAddress2;
        delete form.suburb;
        delete form.state;
        delete form.postCode;
      }
      if (isNew) {
        const isSubmitted = await dispatch(createOrgVenues(form, projectId));
        if (resetPage) resetPage();
        if (isSubmitted) {
          toggle();
          resetForm();
        }
      } else {
        const { venue } = props;
        const isSubmitted = await dispatch(
          updateOrgVenues(venue.id, form, projectId)
        );
        if (isSubmitted) {
          toggle();
          resetForm();
        }

        if (resetPage) resetPage();
      }
    } catch (error) {
      console.error('VenueForm ~ handleValidSubmit ~ error', error);
    }
  };

  const setLocations = (data, values) => {
    const addressNo = get(data, 'streetNumber');
    const addressLine = get(data, 'streetAddress');

    const locationsValue = {
      venueTypeId: values.venueTypeId ? values.venueTypeId : '',
      name: values.name ? values.name : '',
      country: data.country ? data.country : MAP_DEFAULT_COUNTRY_NAME,
      streetAddress:
        addressNo && addressLine
          ? addressNo.concat(', ', addressLine)
          : addressNo || addressLine,
      streetAddress2: '',
      suburb: data.suburb ? data.suburb : '',
      state: data.state ? data.state : '',
      postCode: data.postcode ? data.postcode : '',
    };

    data.business_status && data.name
      ? (locationsValue.name = data.name)
      : (locationsValue.name = '');
    setInitialValues({ ...initialValues, ...locationsValue });
  };

  const onChangeIsOwned = () => {
    setIsOwned(!isOwned);
  };

  const renderVenueTypes = () => {
    if (isEmpty(orgVenueTypes)) return null;
    let venues = sortBy(orgVenueTypes, 'name');
    // Filter out venues where isNotVisible is true
    venues = venues.filter((venue) => !venue.isNotVisible);
    return compact(
      map(venues, (venue, key) => {
        return {
          key,
          text: venue.name,
          value: venue.id,
        };
      })
    );
  };

  const renderCountries = () =>
    map(countries, (country, key) => {
      return {
        key,
        text: country.name,
        value: country.name,
      };
    });

  const changeVenueType = (value) => {
    const venues = find(orgVenueTypes, (item) => item.id === value);
    const isOnline = find(
      orgVenueTypes,
      (item) => item.id === value && item.isOnline === true
    );
    setVenueTypesIsOnline(isOnline ? true : false);
    let subOptions = venues.venueSubOptions;
    subOptions = map(subOptions, (item) => {
      return {
        ...item,
        label: item.name,
        value: item.id,
        checked: false,
      };
    });
    setSubOptions(subOptions);
    //reset sub values
    setSelectedOptions([]);
  };

  const handleChangeOptions = (value, check) => {
    const newValue = parseInt(value);
    let _selectedOption = [...selectedOption];

    var index = _selectedOption.indexOf(newValue);

    if (check === true) {
      _selectedOption.push(newValue);
      setSelectedOptions(uniq(_selectedOption));
    } else {
      if (index > -1) {
        _selectedOption.splice(index, 1);
      }

      _selectedOption = uniq(_selectedOption);
      setSelectedOptions(_selectedOption);
    }
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { resetForm }) => {
        handleValidSubmit(values, resetForm);
      }}
      initialValues={initialValues}
      validationSchema={
        venueTypesIsOnline
          ? addVenueValidation(true)
          : addVenueValidation(false)
      }
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-content">
            {!venueTypesIsOnline ? (
              <>
                <div className="formik-custom-field-margin-top-zero">
                  <CustomDropdown
                    search
                    name="country"
                    id="country"
                    labelText="Country"
                    placeholder="Select country"
                    options={renderCountries()}
                    requiredStar
                  />
                </div>

                <LocationSearchInput
                  label="Search Venue"
                  placeHolder="Enter venue name"
                  customClass="org-inputs-location-search-venue"
                  className="org-inputs-location-search-venue"
                  country={values.country}
                  getLocation={(data) => setLocations(data, values)}
                />
              </>
            ) : null}
            <div className="venue-type">
              <CustomDropdown
                name="venueTypeId"
                id="venueTypeId"
                labelText="Venue Type"
                placeholder="Select venue type"
                options={renderVenueTypes()}
                getValue={(value) => [
                  changeVenueType(value),
                  setFieldValue('subVenueOptions', []),
                ]}
                requiredStar
              />
              {venueTypesDescription ? (
                <label className="venue-type-des">
                  {venueTypesDescription}
                </label>
              ) : null}
            </div>

            {!isEmpty(subOptions) && (
              <CheckboxComp
                options={subOptions}
                id="subVenueOptions"
                name="subVenueOptions"
                setFieldValue={setFieldValue}
                getValue={(e) => {
                  handleChangeOptions(e.target.value, e.target.checked);
                }}
              />
            )}

            <CustomInput
              id="name"
              name="name"
              placeholder="Enter venue name"
              labelText="Venue Name"
              requiredStar
            />

            {!venueTypesIsOnline ? (
              <>
                <CustomInput
                  id="streetAddress"
                  name="streetAddress"
                  placeholder="Enter Address"
                  labelText="Address Line 1"
                  requiredStar
                />

                <CustomInput
                  id="streetAddress2"
                  name="streetAddress2"
                  placeholder="Enter Address"
                  labelText="Address Line 2"
                />

                <div className="venue-address-grid">
                  <div className="venue-address-item">
                    <CustomInput
                      id="suburb"
                      name="suburb"
                      placeholder="Enter Suburb"
                      labelText="Suburb"
                      requiredStar
                    />
                  </div>

                  <div className="venue-address-item">
                    <CustomInput
                      id="state"
                      name="state"
                      placeholder="Enter state"
                      labelText="State / Province"
                      requiredStar
                    />
                  </div>

                  <div className="venue-address-item">
                    <CustomInput
                      id="postCode"
                      name="postCode"
                      placeholder="Enter Postcode"
                      labelText="Postcode"
                      requiredStar
                    />
                  </div>
                </div>

                <CustomCheckbox
                  id="isOwned"
                  name="isOwned"
                  label={`This venue is owned by ${
                    auth.user.organisation && auth.user.organisation.name
                      ? auth.user.organisation.name
                      : 'organisation'
                  }`}
                  checked={isOwned}
                  onChange={() => onChangeIsOwned()}
                />
              </>
            ) : null}
          </div>

          <Divider section />
          <div className="venue-modal-buttons">
            <Button
              disabled={loading}
              loading={loading}
              content={props.isNew ? 'Add Venue' : 'Save Changes'}
              size="small"
              className="Primary-Button"
              type="submit"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

class VenueDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async handleGoalDelete() {
    const { auth, venue, deleteOrgVenues, resetPage } = this.props;
    const orgId = auth.user.organisation ? auth.user.organisation.id : null;
    await deleteOrgVenues(orgId, venue.id);
    if (resetPage) resetPage();
    this.toggle();
  }

  render() {
    const { modal } = this.state;
    const { venue, loading } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClose={() => this.toggle()}
        size="mini"
        trigger={
          <label className="org-users-actions-error first-down">Remove</label>
        }
        className="delete-venue"
      >
        <Modal.Header className="modal-header-font">
          Delete Venue - {venue.name}
        </Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this venue?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => this.toggle()}
            className="Secondary-Button"
            loading={loading}
            disabled={loading}
          >
            No
          </Button>
          <Button
            onClick={() => this.handleGoalDelete()}
            className="Primary-Button"
            content="Yes"
            loading={loading}
            disabled={loading}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export class VenueUpdate extends React.PureComponent {
  //---------------------------------this component is used in Activity Plan UI-------------
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { venue } = this.props;
    return (
      <div className="add-user">
        <Modal
          trigger={
            <label className="org-users-actions-warn  first-down default-color">
              Edit
            </label>
          }
          size="tiny"
          open={modal}
          onOpen={() => this.toggle()}
          closeIcon={
            <div
              className="new-activity-modal-close-icon"
              onClick={() => this.toggle()}
            >
              &times;
            </div>
          }
          className="update-Venue"
        >
          <Modal.Header className="modal-header-font">
            Update Venue - {venue.name}
          </Modal.Header>
          <Modal.Content>
            <VenueForm
              toggle={() => this.toggle()}
              {...this.props}
              isNew={false}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export class VenueCreate extends React.PureComponent {
  //-------------this component is used in Activity Plan UI-------------
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { projectId } = this.props;
    return (
      <div className="add-user">
        <Modal
          className="add-new-venue"
          trigger={
            <Button
              className={projectId ? 'Secondary-Button' : 'Primary-Button'}
            >
              <Icon name="plus" />
              Add New Venue
            </Button>
          }
          size="tiny"
          open={modal}
          onOpen={() => this.toggle()}
          closeIcon={
            <div
              className="new-activity-modal-close-icon"
              onClick={() => this.toggle()}
            >
              &times;
            </div>
          }
        >
          <Modal.Header className="modal-header-font">
            Add New Venue
          </Modal.Header>
          <Modal.Content>
            <VenueForm
              toggle={() => this.toggle()}
              {...this.props}
              isNew={true}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class OrganizationVenues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }

  resetPage() {
    this.setState({
      currentPage: 1,
    });
  }

  columns() {
    return [
      {
        title: 'Venue Type',
        width: '7',
        render: (data) => {
          return (
            <div className="org-users-container">
              <label>{data.name}</label>
              <label className="org-users-text">
                {data.venueType ? data.venueType.name || '-' : '-'}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Suburb',
        width: '5',
        render: (data) => {
          return <label>{data.suburb || '-'}</label>;
        },
      },
      {
        title: '',
        width: '3',
        render: (data) => {
          return (
            <div className="org-users-actions">
              <VenueUpdate
                venue={data}
                resetPage={() => this.resetPage()}
                {...this.props}
              />
              <VenueDelete
                venue={data}
                resetPage={() => this.resetPage()}
                {...this.props}
              />
            </div>
          );
        },
      },
    ];
  }

  async componentDidMount() {
    const { currentPage } = this.state;
    const { auth, getOrgVenuesList, getOrgVenuesTypes, getVenueTypes } =
      this.props;
    getOrgVenuesTypes();
    getVenueTypes();
    if (!isEmpty(auth.user) && auth.user.organisation) {
      getOrgVenuesList(currentPage);
    }
  }

  pageChange(page) {
    const { auth, getOrgVenuesList } = this.props;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        if (!isEmpty(auth.user) && auth.user.organisation) {
          getOrgVenuesList(page);
        }
      }
    );
  }

  render() {
    const { currentPage } = this.state;
    const { orgVenues, loading } = this.props.organization;
    return (
      <Segment className="content-segment org-users" loading={loading}>
        <Grid>
          <Grid.Row className="add-venue-org-row">
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              textAlign="right"
            >
              <VenueCreate {...this.props} resetPage={() => this.resetPage()} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {orgVenues.docs && orgVenues.docs.length ? (
          <div className="org-table-container">
            <CustomTable
              header
              columns={this.columns()}
              data={orgVenues.docs}
              customClass="org-users-table"
              pagination
              handlePaginationChange={(page) => this.pageChange(page)}
              page={currentPage}
              noOfPages={orgVenues.pages}
              keyExtractor={(item, index) => item.id}
            />
          </div>
        ) : (
          <EmptyContainer msg="No venues have been added…" />
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    organization: state.organization,
    extra: state.extra,
    venueTypes: state.extra.venueTypes,
    loading: state.organization.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrgVenuesList,
      getOrgVenuesTypes,
      deleteOrgVenues,
      getVenueTypes,
    },
    dispatch
  );
};

OrganizationVenues = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationVenues);

export { OrganizationVenues };
