/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import '../../../activity.scss';
import React, { useState, useEffect } from 'react';
import { Button, Grid, Icon, Modal, Radio, Form } from 'semantic-ui-react';

import {
  filter,
  find,
  get,
  isEmpty,
  map,
  pick,
  compact,
} from '../../../../../../utils/lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  activateActivity,
  postProjectedLinkedList,
  updateProjectedActivity,
  reInviteLinkActivity,
  getOrganizationUsers,
} from '../../../../../../store/actions';
import { getObjectiveErrors, getPageDisable } from '../../../../../../utils';
import { Formik } from 'formik';

import {
  CustomDropdown,
  CustomInput,
  CustomMultipleSelectionGroupDropDown,
  CustomOptionGroupDropDown,
  CustomRadioGroup,
  CustomTextArea,
  scrollToFirstErrorField,
} from '../../../../../../components/form';

import {
  getLinkedActivitiesFormData,
  linkedActivitiesCreateErrorObject,
  linkedActivitiesGuestUserValidation,
  linkedActivitiesValidationSchema,
} from '../../../../../../validations/linkedActivitiesValidation';
import {
  isGrantLinkedActivity,
  isGrantProgramActivity,
  isNetworkRelationship,
  isParentProgramActivity,
  isSingleLinkedActivity,
} from '../../../../../../utils/activity';
import {
  PARTNER_RELATIONSHIP,
  CREATE_LINKED_MANAGED_BY_OPTIONS,
} from '../../../../../../utils/constant';
import { getLinkActivityFeatureName } from '../../../../../../utils/activity/linkActivity';
import CheckUserEmail from './checkUserEmail';

export default function LinkedActivityForm(props) {
  const dispatch = useDispatch();
  const {
    currentActivity,
    // objectiveTwo,
    linkedActivitiesActivateLoading,
  } = useSelector((state) => state.activity);

  const currencyType = get(currentActivity, 'currencyType', null);
  const { user } = useSelector((state) => state.authentication);
  const { orgUsers } = useSelector((state) => state.organization);

  const { outcomes } = useSelector((state) => state.extra);

  const {
    activity, // editing activity
    projected,
    toggle,
    reSetFilters,
    currentState,
    activityPlanTypeId,
    parentActivityPlanTypeId,
  } = props;

  const isPrimaryCultural = currentActivity.primaryPolicyId === 1;

  // toggles
  const [isActive, setActivateToggle] = useState(false);
  const [isNotifyOwner, setNotifyOwnerToggle] = useState(false);

  const parentActivityOwnerId = get(currentActivity, 'owner.id', null);
  const parentActivityOwnerOrgId = get(
    currentActivity,
    'owner.organisationId',
    null
  );
  // organisationId
  // const _isManagedByAnotherUser =
  //   activity && parentActivityOwnerId
  //     ? parentActivityOwnerId !== get(activity, 'owner.id', null)
  //     : true;
  const [managedBy, setManagedBy] = useState(
    activity && parentActivityOwnerId
      ? parentActivityOwnerId === get(activity, 'owner.id', null)
        ? CREATE_LINKED_MANAGED_BY_OPTIONS[0].value
        : parentActivityOwnerOrgId &&
            parentActivityOwnerOrgId ===
              get(activity, 'owner.organisationId', null)
          ? CREATE_LINKED_MANAGED_BY_OPTIONS[1].value
          : CREATE_LINKED_MANAGED_BY_OPTIONS[2].value
      : CREATE_LINKED_MANAGED_BY_OPTIONS[0].value
  );

  // modal operators
  const [notifyModal, setNotifyModal] = useState(false);
  const [notifyProjectedModal, setNotifyProjectedModal] = useState(false);

  // form validations
  const [formValues, setFormValues] = useState(null);
  const [isExpenditureValid, setIsExpenditureValid] = useState(true);

  const objectiveErrors = getObjectiveErrors(currentActivity);
  const [disabled, setDisabled] = useState(objectiveErrors);
  const [isMultipleDropdownOpen, setIsMultipleDropdownOpen] = useState(false);

  const notifyUser = () => {
    dispatch(reInviteLinkActivity(activity.parentId, activity.id));
    setNotifyModal(false);
  };

  const activateProjected = (updateUser, activityId) => {
    dispatch(
      activateActivity(
        currentActivity.id,
        activityId,
        updateUser,
        isNotifyOwner
      )
    );
    toggle();
  };

  const handleManagedBy = (value, setFieldValue) => {
    if (value !== undefined) {
      // owned by different org user
      if (value === CREATE_LINKED_MANAGED_BY_OPTIONS[2].value) {
        setFieldValue('relationship', PARTNER_RELATIONSHIP[0].value);
        setFieldValue('email', '');
        setFieldValue('organisationId', '');
      } else {
        // internal user / owned by current user
        const email =
          CREATE_LINKED_MANAGED_BY_OPTIONS[0].value === value ? user.email : '';
        setFieldValue('description', '');
        setFieldValue('relationship', '');
        setFieldValue('email', email);
        setFieldValue('organisationId', parentActivityOwnerOrgId);
      }
      setManagedBy(value);
    }
  };

  const handleNotifyOwnerToggle = () => {
    setNotifyOwnerToggle(!isNotifyOwner);
  };

  const handleActivateToggle = () => {
    setActivateToggle(!isActive);
  };

  const handleExpenditureIncome = (value) => {
    if (value === '' || value === null) setIsExpenditureValid(false);
    else {
      if (!isExpenditureValid) setIsExpenditureValid(true);
    }
  };

  const renderObjectiveDomainOne = (excludedItems) => {
    if (!outcomes) return null;

    const objectives = filter(outcomes, (outcome) => {
      if (isSingleLinkedActivity(activityPlanTypeId, true)) {
        const isArrayExcludedItems = Array.isArray(excludedItems);
        let objCondition = false;
        if (isArrayExcludedItems) {
          objCondition = outcome.policyId === currentActivity.primaryPolicyId;
          // &&
          // !excludedItems.includes(outcome.id);
        } else {
          objCondition = outcome.policyId === currentActivity.primaryPolicyId;
          // &&
          // excludedItems !== outcome.id;
        }
        return objCondition;
      }
      return outcome.policyId === currentActivity.primaryPolicyId;
    });

    if (isEmpty(objectives)) return null;

    return compact(
      map(objectives, (objective, key) => {
        const inParent = find(currentActivity.parentOutcomes, (outcome) => {
          return (
            outcome.outcomeId === objective.id &&
            outcome.policyValue === currentActivity.primaryPolicyId
          );
        });

        if (isEmpty(inParent)) return null;
        return {
          key,
          text: objective.title,
          value: objective.id,
          rest: {
            key: objective.policyId,
          },
        };
      })
    );
  };

  const renderObjectiveDomainTwo = (excludedItems) => {
    if (!currentActivity.goal) return null;

    const objectives = filter(outcomes, (outcome) => {
      if (isSingleLinkedActivity(activityPlanTypeId, true)) {
        const isArrayExcludedItems = Array.isArray(excludedItems);
        let objCondition = false;
        if (isArrayExcludedItems) {
          objCondition =
            outcome.policyId === currentActivity.secondaryPolicyId &&
            !excludedItems.includes(outcome.id);
        } else {
          objCondition =
            outcome.policyId === currentActivity.secondaryPolicyId &&
            excludedItems !== outcome.id;
        }

        return objCondition;
      }

      return (
        outcome.policyId === currentActivity.secondaryPolicyId &&
        outcome.policy !== currentActivity.primaryPolicyId
      );
    });

    if (isEmpty(objectives)) return null;

    return compact(
      map(objectives, (objective, key) => {
        const inParent = find(currentActivity.parentOutcomes, (outcome) => {
          return (
            outcome.outcomeId === objective.id &&
            outcome.policyValue === currentActivity.secondaryPolicyId
          );
        });

        if (isEmpty(inParent)) return null;
        return {
          key,
          text: objective.title,
          value: objective.id,
          rest: {
            key: objective.policyId,
          },
        };
      })
    );
  };

  const renderObjectiveOfManyDomain = (policyId, excludedItems) => {
    if (!currentActivity.goal) return null;

    const { activityPlanTypeId } = props;

    const objectives = filter(outcomes, (outcome) => {
      if (isSingleLinkedActivity(activityPlanTypeId, true)) {
        const isArrayExcludedItems = Array.isArray(excludedItems);
        let objCondition = false;
        if (isArrayExcludedItems) {
          objCondition =
            outcome.policyId === policyId &&
            !excludedItems.includes(outcome.id);
        } else {
          objCondition =
            outcome.policyId === policyId && excludedItems !== outcome.id;
        }
        return objCondition;
      }

      return (
        // outcome.policyId === currentActivity.secondaryPolicyId &&
        outcome.policyId !== currentActivity.primaryPolicyId
      );
    });

    if (isEmpty(objectives)) return null;

    return compact(
      map(objectives, (objective, key) => {
        const inParent = find(currentActivity.parentOutcomes, (outcome) => {
          return (
            outcome.outcomeId === objective.id &&
            outcome.policyValue === policyId
          );
        });

        if (isEmpty(inParent)) return null;

        return {
          key,
          text: objective.title,
          value: objective.id,
          rest: {
            key: objective.policyId,
          },
        };
      })
    );
  };

  const renderHeaders = map(currentActivity.parentDomains, (domain, i) => {
    return {
      id: domain.policy.id,
      content: domain.policy.policyName,
    };
  });

  const renderItems = (excludedItems) => {
    return map(currentActivity.parentDomains, (domain, i) => {
      return renderObjectiveOfManyDomain(domain.policyId, excludedItems);
    });
  };

  useEffect(() => {
    dispatch(getOrganizationUsers());

    if (activity) {
      if (!projected) {
        if (!activity.deActive) setActivateToggle(true);
        // if (!activity.guestOwner.entityName) setIsShowEntityName(false);
        setDisabled(true);
        setIsMultipleDropdownOpen(true);
      }
      //  else {
      // if (!activity.isGuestUser) setIsShowEntityName(false);
      // }
    }
  }, []);

  useEffect(() => {
    if (!notifyProjectedModal && formValues) {
      handleSubmit(formValues);
    }
  }, [notifyProjectedModal]);

  useEffect(() => {
    if (!managedBy) {
      setNotifyOwnerToggle(false);
    }
  }, [managedBy]);

  const onSubmit = (values) => {
    if (isNotifyOwner) {
      setNotifyProjectedModal(true);
      setFormValues(values);
    } else {
      handleSubmit(values);
    }
  };

  const handleSubmit = async (formValues) => {
    try {
      let values = Object.assign({}, formValues);

      if (
        values.managedBy === CREATE_LINKED_MANAGED_BY_OPTIONS[2].value &&
        !get(values, 'isChangeAllowed', true)
      ) {
        return;
      }

      delete values.managedBy;
      delete values.isChangeAllowed;
      delete values.isExistingUser;

      if (isEmpty(activity)) {
        values = {
          ...values,
          activityPlanTypeId,
          // isGuestUser: managedBy,
          primaryObjective: values.primaryObjective,
          secondaryObjective: values.secondaryObjective,
        };

        delete values.isGuestUser;
        delete values.organisationId;
        delete values.emailConfirmError;
        delete values.isConfirmedUser;

        if (isParentProgramActivity(activityPlanTypeId)) {
          if (
            currentActivity.parentDomains &&
            currentActivity.parentDomains.length > 0 &&
            currentActivity.parentDomains.find(
              (item) =>
                Number(item.policyId) === Number(currentActivity.parentDomains)
            )
          ) {
          }
        }

        const { activityId } = await dispatch(
          postProjectedLinkedList(currentActivity.id, values)
        );

        if (isActive) {
          activateProjected(false, activityId);
        } else {
          toggle();
        }
      } else {
        //update linked activity

        if (activity.deActive) {
          values = {
            ...values,
            activityPlanTypeId,
            primaryObjective: values.primaryObjective,
            secondaryObjective: values.secondaryObjective,
          };

          await dispatch(
            updateProjectedActivity(
              activity.parentId,
              activity.id,
              isActive,
              pick(values, [
                'firstName',
                'surname',
                'email',
                'phone',
                'activityNotes',
                'primaryObjective',
                'secondaryObjective',
                'name',
                'projectedAmount',
                'entityName',
                'country',
                'address1',
                'address2',
                'suburb',
                'state',
                'postcode',
                'activityPlanTypeId',
                'projectedTotalGrantPool',
                'projectedGrantAmount',
                'relationship',
                'description',
                'isExistingUser',
              ])
            )
          );
        }
        if (isActive) {
          activateProjected(false, activity.id);
        } else {
          toggle();
        }
      }
      // reset table filters
      reSetFilters && reSetFilters();
    } catch (error) {
      console.error('submitData -> error', error);
    }
  };

  const multipleDropdownOpenHandler = (status) => {
    setIsMultipleDropdownOpen(status);
    setDisabled(status);
  };

  const selectFromUsers = () => {
    return orgUsers
      ? compact(
          map(orgUsers, (item, i) =>
            user.id !== item.id
              ? {
                  key: item.id,
                  value: item.email,
                  text: item.name,
                }
              : null
          )
        )
      : [];
  };

  const orgUsersEmails = orgUsers.map((user) => user.email);

  return (
    <div>
      {/* Notification modal */}
      <Modal
        open={notifyModal}
        closeIcon={
          <div
            className="new-activity-modal-close-icon"
            onClick={() => setNotifyModal(false)}
          >
            &times;
          </div>
        }
        size="tiny"
      >
        <Modal.Header className="notify-modal-header">
          Notify Activity Manager
        </Modal.Header>
        <Modal.Content>
          <p>
            Are you sure, do you want to notify the manager of this activity?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="activity-activation-cancel-btn"
            onClick={() => setNotifyModal(false)}
          >
            No
          </Button>
          <Button
            loading={linkedActivitiesActivateLoading}
            disabled={linkedActivitiesActivateLoading}
            className="new-collective-save-btn"
            onClick={() => notifyUser()}
            content="Notify"
          />
        </Modal.Actions>
      </Modal>
      {/* Notification projected modal */}
      <Modal
        open={notifyProjectedModal}
        closeIcon={
          <div
            className="new-activity-modal-close-icon"
            onClick={() => setNotifyProjectedModal(false)}
          >
            &times;
          </div>
        }
        size="tiny"
      >
        <Modal.Header className="notify-modal-header">
          Notify Activity Manager
        </Modal.Header>
        <Modal.Content>
          <p>
            Are you sure, do you want to notify the manager of this activity?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="activity-activation-cancel-btn"
            onClick={() => [
              setNotifyOwnerToggle(false),
              setNotifyProjectedModal(false),
            ]}
          >
            No
          </Button>
          <Button
            className="new-collective-save-btn"
            onClick={() => setNotifyProjectedModal(false)}
            content="Notify"
          />
        </Modal.Actions>
      </Modal>

      <Formik
        onSubmit={(values) => {
          onSubmit(values);
        }}
        initialValues={getLinkedActivitiesFormData(
          activity,
          projected,
          activityPlanTypeId,
          parentActivityPlanTypeId,
          user.email
        )}
        validationSchema={
          managedBy === CREATE_LINKED_MANAGED_BY_OPTIONS[2].value
            ? linkedActivitiesGuestUserValidation(
                !isEmpty(currentActivity.parentDomains) ||
                  currentActivity.secondaryPolicy,
                activityPlanTypeId,
                parentActivityPlanTypeId,
                isPrimaryCultural,
                orgUsersEmails
              )
            : linkedActivitiesValidationSchema(
                currentActivity.secondaryPolicy,
                activityPlanTypeId,
                parentActivityPlanTypeId,
                isPrimaryCultural,
                orgUsersEmails
              )
        }
      >
        {({
          handleSubmit,
          errors,
          isSubmitting,
          values,
          setFieldValue,
          touched,
        }) => {
          const { primaryObjective = null, secondaryObjective = null } = values;

          const isPrimaryObjectiveEmpty = Array.isArray(primaryObjective)
            ? isEmpty(primaryObjective)
            : primaryObjective === '' || !primaryObjective;

          // const isSecondaryObjectiveEmpty = isEmpty(
          //   Array.isArray(new Array(secondaryObjective))
          // );

          return (
            <Form onSubmit={handleSubmit}>
              {scrollToFirstErrorField(
                linkedActivitiesCreateErrorObject(
                  errors,
                  managedBy === CREATE_LINKED_MANAGED_BY_OPTIONS[2].value,
                  activityPlanTypeId,
                  parentActivityPlanTypeId
                ),
                isSubmitting
              )}
              <Grid className="mb-1">
                {objectiveErrors && (
                  <div className="issues-box w-100">
                    <div className="padding-issue ">
                      <ul>
                        {objectiveErrors.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      </ul>
                    </div>
                  </div>
                )}
                <Grid.Column className="linked-activity-model-column">
                  <label className="goal-label">Goal</label>
                  <div>
                    <label className="evidence-modal-label">
                      {get(currentActivity, 'goal')}
                    </label>
                  </div>
                </Grid.Column>
              </Grid>
              <Grid className="mb-1">
                <Grid.Column
                  className="linked-activity-model-column"
                  mobile={16}
                  tablet={8}
                  computer={8}
                >
                  <label className="goal-label">Primary Domain</label>
                  <div>
                    {currentActivity.primaryPolicy && (
                      <label className="evidence-modal-label">
                        {currentActivity.primaryPolicy.policyName}
                      </label>
                    )}
                  </div>
                </Grid.Column>
                {!isEmpty(currentActivity.parentDomains) && (
                  <Grid.Column
                    className="linked-activity-model-column"
                    mobile={16}
                    tablet={8}
                    computer={8}
                  >
                    <label className="goal-label">Secondary Domain</label>

                    <ul className="linked-activity-model-margin-bottom">
                      {map(currentActivity.parentDomains, (domain, i) => (
                        <li key={i} className="evidence-modal-label">
                          {domain.policy.policyName}
                        </li>
                      ))}
                    </ul>
                  </Grid.Column>
                )}
                {!isEmpty(currentActivity.secondaryPolicy) && (
                  <Grid.Column
                    className="linked-activity-model-column"
                    mobile={16}
                    tablet={8}
                    computer={8}
                  >
                    <label className="goal-label">Secondary Domain</label>
                    <div>
                      <label className="evidence-modal-label">
                        {currentActivity.secondaryPolicy.policyName}
                      </label>
                    </div>
                  </Grid.Column>
                )}
              </Grid>

              {isParentProgramActivity(activityPlanTypeId) ? (
                <div>
                  <CustomMultipleSelectionGroupDropDown
                    name="primaryObjective"
                    id="primaryObjective"
                    labelText="Primary Objective"
                    placeholder="Select Primary Objective"
                    renderHeaders={
                      objectiveErrors || isEmpty(currentActivity.primaryPolicy)
                        ? []
                        : [
                            {
                              id: currentActivity.primaryPolicy.id,
                              content: currentActivity.primaryPolicy.policyName,
                            },
                          ]
                    }
                    renderItems={
                      objectiveErrors || isEmpty(currentActivity.primaryPolicy)
                        ? []
                        : [renderObjectiveDomainOne(secondaryObjective)]
                    }
                    values={values}
                    defaultValuesTexts={get(
                      activity,
                      'primaryParentObjectives',
                      []
                    )}
                    setDisabledOtherFields={(status) =>
                      multipleDropdownOpenHandler(status)
                    }
                    disabled={disabled}
                    isView={activity && !projected} // activates activity (only view )
                    requiredStar
                  />
                </div>
              ) : (
                <CustomDropdown
                  name="primaryObjective"
                  id="primaryObjective"
                  labelText="Primary Objective"
                  options={
                    objectiveErrors
                      ? []
                      : renderObjectiveDomainOne(secondaryObjective)
                  }
                  placeholder="Select Primary Objective"
                  selection
                  disabled={disabled}
                  requiredStar
                />
              )}

              {/*
               * If Select Goal is cultural secondary policies will be optional fields for linked activities
               */}
              {(!isEmpty(currentActivity.parentDomains) &&
                isGrantLinkedActivity(activityPlanTypeId)) ||
              (!isEmpty(currentActivity.parentDomains) &&
                isSingleLinkedActivity(activityPlanTypeId, true)) ? (
                <CustomOptionGroupDropDown
                  name="secondaryObjective"
                  id="secondaryObjective"
                  labelText="Secondary Objective"
                  placeholder="Select Secondary Objective"
                  renderHeaders={objectiveErrors ? [] : renderHeaders}
                  renderItems={
                    objectiveErrors ? [] : renderItems(primaryObjective)
                  }
                  disabled={disabled || isPrimaryObjectiveEmpty}
                  formValues={values}
                  resetTextByFormVal="primaryObjective"
                  requiredStar={!isPrimaryCultural}
                />
              ) : //    for program activities (cultural for primary objective / has multiple secondary domains and objectives)

              isParentProgramActivity(activityPlanTypeId) &&
                !isEmpty(currentActivity.parentDomains) ? (
                <CustomMultipleSelectionGroupDropDown
                  name="secondaryObjective"
                  id="secondaryObjective"
                  labelText="Secondary Objective"
                  placeholder="Select Secondary Objective"
                  renderHeaders={objectiveErrors ? [] : renderHeaders}
                  renderItems={
                    objectiveErrors ? [] : renderItems(primaryObjective)
                  }
                  values={values}
                  defaultValuesTexts={get(
                    activity,
                    'secondaryParentObjectives',
                    []
                  )}
                  setDisabledOtherFields={(status) =>
                    multipleDropdownOpenHandler(status)
                  }
                  isView={activity && !projected} // activates activity (only view )
                  disabled={disabled || isPrimaryObjectiveEmpty}
                  requiredStar={!isPrimaryCultural}
                />
              ) : isParentProgramActivity(activityPlanTypeId) &&
                isEmpty(currentActivity.parentDomains) &&
                !isEmpty(currentActivity.secondaryPolicy) ? (
                <CustomMultipleSelectionGroupDropDown
                  name="secondaryObjective"
                  id="secondaryObjective"
                  labelText="Secondary Objective"
                  placeholder="Select Secondary Objective"
                  renderHeaders={
                    objectiveErrors
                      ? []
                      : [
                          {
                            id: currentActivity.secondaryPolicy.id,
                            content: currentActivity.secondaryPolicy.policyName,
                          },
                        ]
                  }
                  renderItems={
                    objectiveErrors
                      ? []
                      : [renderObjectiveDomainTwo(primaryObjective)]
                  }
                  values={values}
                  defaultValuesTexts={get(
                    activity,
                    'secondaryParentObjectives',
                    []
                  )}
                  setDisabledOtherFields={(status) =>
                    multipleDropdownOpenHandler(status)
                  }
                  disabled={disabled || isPrimaryObjectiveEmpty}
                  isView={activity && !projected} // activates activity (only view )
                  requiredStar={!isPrimaryCultural}
                />
              ) : (
                !isEmpty(currentActivity.secondaryPolicy) && (
                  <CustomDropdown
                    name="secondaryObjective"
                    id="secondaryObjective"
                    labelText="Secondary Objective"
                    options={
                      objectiveErrors
                        ? []
                        : renderObjectiveDomainTwo(primaryObjective)
                    }
                    placeholder="Select Secondary Objective"
                    selection
                    disabled={disabled || isPrimaryObjectiveEmpty}
                    requiredStar={!isPrimaryCultural}
                  />
                )
              )}
              <CustomInput
                id="name"
                name="name"
                placeholder="Enter activity name"
                labelText="Activity Name"
                disabled={disabled}
                requiredStar
              />
              <CustomTextArea
                id="activityNotes"
                name="activityNotes"
                placeholder="Enter activity notes"
                labelText=" Activity Notes"
                disabled={disabled}
              />
              <div className="email-verification mt-5">
                <Grid>
                  {isGrantProgramActivity(
                    currentActivity.activityPlanTypeId
                  ) && (
                    <Grid.Column
                      className="income-div linked-activity-model-column"
                      mobile={16}
                      tablet={8}
                      computer={8}
                    >
                      <CustomInput
                        id="grantId"
                        name="grantId"
                        type="text"
                        placeholder="Grant ID"
                        labelText="Grant ID"
                        disabled={disabled}
                        requiredStar
                      />
                    </Grid.Column>
                  )}
                </Grid>
                <div className="radio-padding">
                  <Grid>
                    <Grid.Column className="linked-activity-model-column">
                      <div className="manage-user-padding">
                        <div className="formik-custom-field act-inputs-new">
                          <label>This activity will be managed by:</label>
                        </div>
                      </div>

                      <CustomRadioGroup
                        options={CREATE_LINKED_MANAGED_BY_OPTIONS}
                        id="managedBy"
                        name="managedBy"
                        type="radio"
                        getValue={(e) => handleManagedBy(e, setFieldValue)}
                        setDefaultValue={managedBy}
                        disabled={isMultipleDropdownOpen}
                      />
                    </Grid.Column>
                  </Grid>
                </div>

                {managedBy === CREATE_LINKED_MANAGED_BY_OPTIONS[1].value && (
                  <div className="radio-padding">
                    <Grid>
                      <Grid.Column>
                        <CustomDropdown
                          search
                          selection
                          upward
                          name="email"
                          id="email"
                          labelText="Activity Manager"
                          className="org-inputs"
                          placeholder="Select organisation user"
                          options={selectFromUsers()}
                          disabled={disabled}
                          // value={activityFromId}
                        />
                      </Grid.Column>
                    </Grid>
                  </div>
                )}

                {managedBy === CREATE_LINKED_MANAGED_BY_OPTIONS[2].value && (
                  <CheckUserEmail
                    id="email"
                    activityFeatureRequired={getLinkActivityFeatureName(
                      activityPlanTypeId
                    )}
                    activityManager={
                      managedBy === CREATE_LINKED_MANAGED_BY_OPTIONS[2].value &&
                      values.email
                        ? get(activity, 'owner', null)
                        : null
                    }
                    setFieldValue={setFieldValue}
                  />
                )}

                {/* Relationship and Support Description */}
                {managedBy === CREATE_LINKED_MANAGED_BY_OPTIONS[2].value && (
                  <div>
                    <div className="radio-padding">
                      <Grid>
                        <Grid.Column className="linked-activity-model-column">
                          <div className="formik-custom-field act-inputs-new">
                            <label>Relationship</label>
                          </div>
                          <CustomRadioGroup
                            options={PARTNER_RELATIONSHIP}
                            id="relationship"
                            name="relationship"
                            type="radio"
                            disabled={isMultipleDropdownOpen}
                            requiredStar
                          />
                        </Grid.Column>
                      </Grid>
                    </div>
                  </div>
                )}

                {!isNetworkRelationship(values.relationship) && (
                  <Grid>
                    <Grid.Column
                      className="income-div linked-activity-model-column"
                      mobile={16}
                      tablet={8}
                      computer={8}
                    >
                      <CustomInput
                        id="projectedGrantAmount"
                        name="projectedGrantAmount"
                        type="number"
                        placeholder="0.00"
                        labelText="Program Contribution"
                        icon={
                          <Icon
                            circular
                            inverted
                            name={get(currencyType, 'iconName', 'dollar sign')}
                            color={isExpenditureValid ? 'grey' : 'red'}
                          />
                        }
                        iconPosition="left"
                        disabled={disabled}
                        getValue={(e) => handleExpenditureIncome(e)}
                        requiredStar
                      />
                    </Grid.Column>
                  </Grid>
                )}

                {/*
                 * values.organisationId -> to check guest user
                 *   values.organisationId !== parentActivityOwnerOrgId -> to check parent activity owner org ne activity owner org
                 */}
                {managedBy === CREATE_LINKED_MANAGED_BY_OPTIONS[2].value && (
                  <CustomTextArea
                    id="description"
                    name="description"
                    disabled={isMultipleDropdownOpen}
                    placeholder="Enter support description"
                    labelText="Support Description"
                  />
                )}

                {!getPageDisable(currentActivity, user, currentState) &&
                  (projected || !activity) && (
                    <div className="activate-notify">
                      <Grid className="activate-notify-row">
                        <Grid.Column className="evaluation-activity-toggel-group linked-activity-model-column">
                          <label className="lbl-margin-left">Activate</label>
                          <Radio
                            toggle
                            name="activateToggel"
                            checked={isActive}
                            disabled={false} //isMultipleDropdownOpen
                            onChange={handleActivateToggle}
                          />
                          {managedBy ===
                            CREATE_LINKED_MANAGED_BY_OPTIONS[2].value &&
                            isActive && (
                              <>
                                <label className="lbl-margin-left">
                                  Notify Activity Manager
                                </label>
                                <Radio
                                  toggle
                                  name="notifyOwnerToggle"
                                  checked={isNotifyOwner}
                                  disabled={isMultipleDropdownOpen}
                                  onChange={handleNotifyOwnerToggle}
                                />
                              </>
                            )}
                        </Grid.Column>
                      </Grid>
                      <Grid>
                        <Grid.Column
                          textAlign="right"
                          verticalAlign="middle"
                          className=""
                        >
                          <Button
                            content={activity ? 'Save' : 'Create Activity'}
                            type="submit"
                            className="Primary-Button"
                            disabled={disabled || isMultipleDropdownOpen}
                          />
                        </Grid.Column>
                      </Grid>
                    </div>
                  )}
              </div>
            </Form>
          );
        }}
      </Formik>

      {!projected && activity ? (
        <Grid>
          <Grid.Column textAlign="right" verticalAlign="middle">
            <div className="activity-model-buttons">
              {activity.statusId !== 3 &&
              !activity.review &&
              !getPageDisable(currentActivity, user, currentState) ? (
                <Button
                  content={
                    activity.isNotified
                      ? 'Re-Send Notification'
                      : 'Notify Activity Manager'
                  }
                  onClick={() => setNotifyModal(true)}
                  basic
                  color="orange"
                  className="activity-reinvite-button"
                />
              ) : null}
              <Button
                content="Close"
                className="activity-confirm-button"
                onClick={() => toggle()}
              />
            </div>
          </Grid.Column>
        </Grid>
      ) : null}
    </div>
  );
}
